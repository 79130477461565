import { useState, useEffect } from 'react'

export const useAuthStatus = () => {
  const [loggedIn, setLoggedIn] = useState(false)
  const [checkingStatus, setCheckingStatus] = useState(true)

  const user = JSON.parse(localStorage.getItem('user'))
  const auth_token = JSON.parse(localStorage.getItem('auth_token'))

  useEffect(() => {
    if (user && auth_token) {
      setLoggedIn(true)
    } else {
      setLoggedIn(false)
    }

    setCheckingStatus(false)
  }, [user, auth_token])

  return { loggedIn, checkingStatus }
}
