import { useNavigate } from 'react-router-dom'

function ExplainerVid() {
  const navigate = useNavigate()

  const onSubmit = (e) => {
    e.preventDefault()
    //verify info
    navigate('/confirmdetails')
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-3 hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <h1 className='text-center canary-h1'>How This Works</h1>
          <p className='text-left'>
            Before we confirm your details, please watch this demo on how to
            best protect your assets using Canary.
          </p>
          <form onSubmit={onSubmit}>
            <div className='mb-3 text-center'>
              <iframe
                width='560'
                height='315'
                src='https://www.youtube.com/embed/EZpwDp2RyPs'
                title='YouTube video player'
                frameborder='0'
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowfullscreen
              ></iframe>
            </div>
            <div className='text-center'>
              <button
                type='submit'
                className='btn btn-primary w-100 canary-btn-blue'
              >
                Final Step: Confirmation
              </button>
            </div>
          </form>
        </div>

        <div className='col-3 hidden-xs hidden-sm'></div>
      </div>
    </div>
  )
}

export default ExplainerVid
