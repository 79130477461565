import axios from 'axios'

let API_URL

if (process.env.NODE_ENV === 'production') {
  API_URL = 'https://be-api.lindywallet.com'
} else {
  API_URL = 'http://localhost:3000'
}

console.log(API_URL)

// register a user and save the info to local storage
const createUser = async (userData) => {
  const response = await axios.post(`${API_URL}/api/v1/user`, userData)
  if (response.data) {
    console.log('at the api call')
    console.log(response.data.data)
    console.log(response.data.auth.token)
    localStorage.setItem('user', JSON.stringify(response.data.data))
    localStorage.setItem('auth_token', JSON.stringify(response.data.auth.token))
  }
  return response.data
}

// trigger the email OTP
const emailOTPTrigger = async (email) => {
  const response = await axios.post(`${API_URL}/api/v1/user/self/email`, email)
  return response
}

const emailOTPVerify = async (OTPData) => {
  const response = await axios.post(
    `${API_URL}/api/v1/user/self/email/verify`,
    OTPData
  )
  console.log(response)
  // return response.data
  // localStorage.setItem('user', JSON.stringify(userData))
  return response
}

const emailOTPUpdate = async (OTPData) => {
  const response = await axios.put(
    `${API_URL}/api/v1/user/self/email/verify`,
    OTPData
  )
  console.log(response)
  // return response.data
  // localStorage.setItem('user', JSON.stringify(userData))
  return response
}

// trigger the SMS OTP
const smsOTPTrigger = async (sms) => {
  const response = await axios.post(`${API_URL}/api/v1/user/self/phone`, sms, {
    withCredentials: true,
  })
  console.log('SMS OTP Trigger')
  console.log(response)

  return response
}

const smsOTPVerify = async (OTPData) => {
  const response = await axios.post(
    `${API_URL}/api/v1/user/self/phone/verify`,
    OTPData
  )
  console.log('SMS OTP Verify')
  console.log(response)
  return response
}

const smsOTPUpdate = async (OTPData) => {
  const response = await axios.put(
    `${API_URL}/api/v1/user/self/phone/verify`,
    OTPData
  )
  console.log('SMS OTP Update')
  console.log(response)
  return response
}

const TOTPVerify = async (OTPData) => {
  const response = await axios.post(
    `${API_URL}/api/v1/user/self/totp/verify`,
    OTPData
  )
  console.log('TOTP Verify')
  console.log(response)
  return response
}

const TOTPReAuth = async (OTPData) => {
  const response = await axios.post(`${API_URL}/api/v1/auth/reauth`, OTPData)
  return response
}
// update the backup wallet
const updateBackupWallet = async (backupwallet) => {
  const response = await axios.put(`${API_URL}/api/v1/user/self`, backupwallet)
  console.log('Update Backup Wallet')
  console.log(response)
  return response
}

// Logout user

const logout = async () => {
  localStorage.removeItem('user')
  localStorage.removeItem('auth_token')
}

// login a user and save the info to local storage

const login = async (userData) => {
  const response = await axios.post(`${API_URL}/api/v1/auth/login`, userData)
  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data.data))
    localStorage.setItem('auth_token', JSON.stringify(response.data.auth.token))
  }
  return response.data
}

const authService = {
  createUser,
  emailOTPTrigger,
  emailOTPVerify,
  emailOTPUpdate,
  smsOTPTrigger,
  smsOTPVerify,
  smsOTPUpdate,
  TOTPVerify,
  TOTPReAuth,
  updateBackupWallet,
  logout,
  login,
}

export default authService
