import { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { createUser, reset } from '../features/auth/authSlice'
import { toast } from 'react-toastify'

function ConnectWallet() {
  // Get user from localstorage
  const { user, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  // create a variable to store the public wallet
  const [currentAccount, setCurrentAccount] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isError) {
      // error or they have an account
      console.log(user)
      if (user && user.totp_verified) {
        dispatch(reset())
        navigate('/signin2fa')
        return
      }

      toast.error('Error Creating Account')
      dispatch(reset())
    }

    if (isSuccess) {
      navigate('/verifyemail')
      console.log('success')
      dispatch(reset())
    }
  }, [isError, isSuccess, dispatch, navigate, user])

  useEffect(() => {
    if (!user) {
      return navigate('/connectwallet')
    }
    if (!user.email_verified) {
      return navigate('/verifyemail')
    }
    if (!user.phone_verified) {
      return navigate('/verifysms')
    }

    if (user.backup_address === '') {
      return navigate('/backupwallet')
    }

    if (!user.totp_verified) {
      return navigate('/authenticator')
    }

    // add redirect for non paid or paid plan expired
  }, [user, navigate])

  const connectWallet = async () => {
    try {
      const { ethereum } = window

      if (!ethereum) {
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          window.location =
            'https://metamask.app.link/dapp/app.lindywallet.com/'
          return
        }
        toast.error('You need a Metamask Wallet')
        return
      }

      // pop up the metamask wallet to get permission
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
      console.log(accounts)
      // we want to get the first one
      const account = accounts[0]
      setCurrentAccount(account)

      // create account in the system
      const userData = {
        wallet_type: 0,
        account_id: account,
      }

      console.log('hello')
      const result = dispatch(createUser(userData))

      // if success then the user was created....
      console.log(result)
    } catch (error) {
      console.log(error)
    }
  }

  const checkIfWalletIsConnected = async () => {
    try {
      const { ethereum } = window

      if (!ethereum) {
        console.log('You need a Metamask Wallet')
        return
      } else {
        console.log('You have a metamask wallet')
      }

      const accounts = await ethereum.request({ method: 'eth_accounts' })

      if (accounts.length !== 0) {
        // this means we have an account that is authorized on our app
        // we need to check where they are in the steps as well
        const account = accounts[0]
        setCurrentAccount(account)
        // get the user info from the backend
        // dispatch to the login
        // dispatch(updatewalletAddress(account))
      } else {
        // we should delete the local storage
        localStorage.removeItem('user')
        console.log('No Authorized Accounts Found')
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-3 hidden-md hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <h2 className='text-center canary-welcome-header'>
            Welcome to Canary 👋🏾
          </h2>
          <div className='text-center'>
            <button
              type='button'
              onClick={connectWallet}
              className='btn btn-primary'
            >
              Connect Wallet
            </button>
          </div>
        </div>
        <div className='col-3 hidden-xs hidden-sm'></div>
      </div>
    </div>
  )
}

export default ConnectWallet
