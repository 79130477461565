import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// Get user from localstorage
const user = JSON.parse(localStorage.getItem('user'))
const auth_token = JSON.parse(localStorage.getItem('auth_token'))

const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  otpSuccess: false,
  reauth_token: user ? user.reauth_token : '',
  auth_token: auth_token ? auth_token : '',
  message: '',
  email: user ? user.email : '',
  phone: user ? user.phone : '',
  walletAddress: user ? user.walletAddress : '',
  backupWalletAddress: user ? user.backupWalletAddress : '',
  paid_plan: 0,
  otp_token: '',
}

// register a new user
export const createUser = createAsyncThunk(
  'auth/createUser',
  async (userData, thunkAPI) => {
    try {
      return await authService.createUser(userData)
    } catch (error) {
      const message =
        error.response.data.message ||
        error.response.data ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// trigger an email OTP code
export const emailOTPTrigger = createAsyncThunk(
  'auth/emailOTPTrigger',
  async (email, thunkAPI) => {
    try {
      const auth_token = JSON.parse(localStorage.getItem('auth_token'))
      const auth = {
        token: auth_token,
      }
      const payload = { auth, ...email }
      return await authService.emailOTPTrigger(payload)
    } catch (error) {
      const message =
        error.response.data.message ||
        error.response.data ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// trigger an SMS OTP code
export const smsOTPTrigger = createAsyncThunk(
  'auth/smsOTPTrigger',
  async (sms, thunkAPI) => {
    try {
      const auth_token = JSON.parse(localStorage.getItem('auth_token'))
      const auth = {
        token: auth_token,
      }
      const payload = { auth, ...sms }
      return await authService.smsOTPTrigger(payload)
    } catch (error) {
      const message =
        error.response.data.message ||
        error.response.data ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// verify the email OTP code

export const emailOTPVerify = createAsyncThunk(
  'auth/emailOTPVerify',
  async (OTPData, thunkAPI) => {
    try {
      const auth_token = JSON.parse(localStorage.getItem('auth_token'))
      const auth = {
        token: auth_token,
      }
      const payload = { auth, ...OTPData }
      return await authService.emailOTPVerify(payload)
    } catch (error) {
      const message =
        error.response.data.message ||
        error.response.data ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// update the email OTP code

export const emailOTPUpdate = createAsyncThunk(
  'auth/emailOTPUpdate',
  async (OTPData, thunkAPI) => {
    try {
      const auth_token = JSON.parse(localStorage.getItem('auth_token'))
      const auth = {
        token: auth_token,
      }
      const payload = { auth, ...OTPData }
      return await authService.emailOTPUpdate(payload)
    } catch (error) {
      const message =
        error.response.data.message ||
        error.response.data ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// verify the SMS OTP code

export const smsOTPVerify = createAsyncThunk(
  'auth/smsOTPVerify',
  async (OTPData, thunkAPI) => {
    try {
      const auth_token = JSON.parse(localStorage.getItem('auth_token'))
      const auth = {
        token: auth_token,
      }
      const payload = { auth, ...OTPData }
      return await authService.smsOTPVerify(payload)
    } catch (error) {
      const message =
        error.response.data.message ||
        error.response.data ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const smsOTPUpdate = createAsyncThunk(
  'auth/smsOTPUpdate',
  async (OTPData, thunkAPI) => {
    try {
      const auth_token = JSON.parse(localStorage.getItem('auth_token'))
      const auth = {
        token: auth_token,
      }
      const payload = { auth, ...OTPData }
      return await authService.smsOTPUpdate(payload)
    } catch (error) {
      const message =
        error.response.data.message ||
        error.response.data ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// verify the TOTP code

export const TOTPVerify = createAsyncThunk(
  'auth/TOTPVerify',
  async (OTPData, thunkAPI) => {
    try {
      const auth_token = JSON.parse(localStorage.getItem('auth_token'))
      const auth = {
        token: auth_token,
      }
      const payload = { auth, ...OTPData }
      return await authService.TOTPVerify(payload)
    } catch (error) {
      const message =
        error.response.data.message ||
        error.response.data ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const TOTPReAuth = createAsyncThunk(
  'auth/TOTPReAuth',
  async (OTPData, thunkAPI) => {
    try {
      const auth_token = JSON.parse(localStorage.getItem('auth_token'))
      const auth = {
        token: auth_token,
      }
      const payload = { auth, ...OTPData }
      console.log(payload)
      return await authService.TOTPReAuth(payload)
    } catch (error) {
      const message =
        error.response.data.message ||
        error.response.data ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const updateBackupWallet = createAsyncThunk(
  'auth/updateBackupWallet',
  async (walletAddress, thunkAPI) => {
    try {
      const auth_token = JSON.parse(localStorage.getItem('auth_token'))
      const auth = {
        token: auth_token,
      }
      const payload = { auth, ...walletAddress }
      console.log('payload')
      console.log(payload)
      console.log('payload')
      return await authService.updateBackupWallet(payload)
    } catch (error) {
      const message =
        error.response.data.message ||
        error.response.data ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// register a new api user
export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    return await authService.logout()
  } catch (error) {
    const message =
      error.response.data.message ||
      error.response.data ||
      error.message ||
      error.toString()

    return thunkAPI.rejectWithValue(message)
  }
})

// login a user
export const login = createAsyncThunk(
  'auth/login',
  async (userData, thunkAPI) => {
    try {
      return await authService.login(userData)
    } catch (error) {
      const message =
        error.response.data.message ||
        error.response.data ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)

// check if wallet is connected
export const checkIfWalletIsConnected = createAsyncThunk(
  'auth/checkIfWalletIsConnected',
  async (_, thunkAPI) => {
    try {
      const { ethereum } = window

      if (!ethereum) {
        return false
      }
      const accounts = await ethereum.request({ method: 'eth_accounts' })

      if (accounts.length !== 0) {
        return true
      } else {
        console.log('no wallet authorized')
        return false
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(false)
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.otpSuccess = false
      state.message = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkIfWalletIsConnected.fulfilled, (state, action) => {
        state.metamask_connected = action.payload
      })
      .addCase(checkIfWalletIsConnected.rejected, (state, action) => {
        state.metamask_connected = action.payload
      })
      .addCase(createUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        //save the token
        console.log(action.payload)
        localStorage.setItem(
          'auth_token',
          JSON.stringify(action.payload.auth.token)
        )
        state.auth_token = action.payload.auth.token
        state.user = action.payload.data
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoading = false
        console.log(action.payload)
        state.user = action.payload.data
        state.isError = true
      })
      .addCase(emailOTPTrigger.pending, (state) => {
        state.isLoading = true
      })
      .addCase(emailOTPTrigger.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true

        state.otp_token = action.payload.data.otp_token
      })
      .addCase(emailOTPTrigger.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(emailOTPVerify.pending, (state) => {
        state.isLoading = true
      })
      .addCase(emailOTPVerify.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.otpSuccess = true
        console.log(action.payload.data)
        const user = action.payload.data.data

        localStorage.setItem('user', JSON.stringify(user))
        state.user = user
      })
      .addCase(emailOTPVerify.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(emailOTPUpdate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(emailOTPUpdate.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.otpSuccess = true
        const user = action.payload.data.data
        localStorage.setItem('user', JSON.stringify(user))
        state.user = user
      })
      .addCase(emailOTPUpdate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(smsOTPTrigger.pending, (state) => {
        state.isLoading = true
      })
      .addCase(smsOTPTrigger.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        console.log(action.payload.data)
        state.otp_token = action.payload.data.otp_token
      })
      .addCase(smsOTPTrigger.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(smsOTPVerify.pending, (state) => {
        state.isLoading = true
      })
      .addCase(smsOTPVerify.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.otpSuccess = true
        const user = action.payload.data.data

        localStorage.setItem('user', JSON.stringify(user))
        state.user = user
      })
      .addCase(smsOTPUpdate.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(smsOTPUpdate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(smsOTPUpdate.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.otpSuccess = true
        const user = action.payload.data.data
        localStorage.setItem('user', JSON.stringify(user))
        state.user = user
      })
      .addCase(smsOTPVerify.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(TOTPVerify.pending, (state) => {
        state.isLoading = true
      })
      .addCase(TOTPVerify.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        const user = action.payload.data.data

        localStorage.setItem('user', JSON.stringify(user))
        state.user = user
      })
      .addCase(TOTPVerify.rejected, (state, action) => {
        state.isLoading = false
        state.user = null
        state.isError = true
        state.message = action.payload
      })
      .addCase(TOTPReAuth.pending, (state) => {
        state.isLoading = true
      })
      .addCase(TOTPReAuth.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        const reauth_token = action.payload.data.data.reauth_token
        state.reauth_token = reauth_token
        console.log('reauth token', reauth_token)
        let user = JSON.parse(localStorage.getItem('user'))
        user = {
          ...user,
          reauth_token,
        }

        console.log('new user: ', user)

        localStorage.setItem('user', JSON.stringify(user))
        state.user = user
      })
      .addCase(TOTPReAuth.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateBackupWallet.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateBackupWallet.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        const user = action.payload.data.data
        localStorage.setItem('user', JSON.stringify(user))
        state.user = user
      })
      .addCase(updateBackupWallet.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false
        state.user = null
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        console.log('LOGIN')
        console.log(action.payload.data)
        console.log(action.payload)
        localStorage.setItem(
          'auth_token',
          JSON.stringify(action.payload.auth.token)
        )
        state.auth_token = action.payload.auth.token
        localStorage.setItem('user', JSON.stringify(action.payload.data))
        state.user = action.payload.data
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.user = null
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset } = authSlice.actions

export default authSlice.reducer
