import axios from 'axios'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

function Pricing() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user, auth_token } = useSelector((state) => state.auth)

  useEffect(() => {
    if (!user) {
      return navigate('/connectwallet')
    }
    if (!user.email_verified) {
      return navigate('/verifyemail')
    }
    if (!user.phone_verified) {
      return navigate('/verifysms')
    }

    if (user.backup_address === '') {
      return navigate('/backupwallet')
    }

    if (!user.totp_verified) {
      return navigate('/authenticator')
    }

    // add redirect for non paid or paid plan expired
  }, [user, navigate])

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search)

    if (query.get('payment_failed')) {
      toast.error(
        "Order Failed -- please select a plan and checkout when you're ready."
      )
    }
    if (query.get('payment_canceled')) {
      toast.error(
        "Order Canceled -- please select a plan and checkout when you're ready."
      )
    }
  }, [])

  const paymentTrigger = async () => {
    try {
      const auth = {
        token: auth_token,
      }
      const payload = { auth }
      let API_URL

      if (process.env.NODE_ENV === 'production') {
        API_URL = 'https://be-api.lindywallet.com/api/v1/checkout/request'
      } else {
        API_URL = 'http://localhost:3000/api/v1/checkout/request'
      }

      const result = await axios.post(API_URL, payload)
      console.log(result)
      if (result.data) {
        if (result.data.success) {
          const redirect_url = result.data.data.redirect_url
          url_redirect(redirect_url)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  function url_redirect(url) {
    var X = setTimeout(function () {
      window.location.replace(url)
      return true
    }, 300)

    if ((window.location = url)) {
      clearTimeout(X)
      return true
    } else {
      if ((window.location.href = url)) {
        clearTimeout(X)
        return true
      } else {
        clearTimeout(X)
        window.location.replace(url)
        return true
      }
    }
    return false
  }

  const checkOut = (e) => {
    e.preventDefault()
    paymentTrigger()
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-3 hidden-md hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12 price-col'>
          <h1 className='text-left price-h1'>
            FREE/<span>month</span>
          </h1>

          <div className='price-box text-left'>
            <p className='price-explainer'>
              You will automatically be subscribed to our paid plan
              ($9.99/month) after your 1 month free trial
            </p>
            <p className='price-ul-header'>Your plan includes</p>
            <ul className='plan-info'>
              <li>SMS Notifications</li>
              <li>Email Notifications</li>
              <li>Eject assets feature</li>
              <li>1 Month FREE</li>
            </ul>
          </div>
          <div className='mb-3'>
            <div className='text-center'>
              <button
                type='submit'
                onClick={checkOut}
                className='btn btn-primary w-100 canary-btn-blue'
              >
                Secure My Wallet!
              </button>
              <p className='price-disclaimer'>
                Cancel anytime and continue to receive FREE email notifications
                on all activity in your wallet
              </p>
            </div>
          </div>
        </div>
        <div className='col-3 hidden-xs hidden-sm'></div>
      </div>
    </div>
  )
}

export default Pricing
