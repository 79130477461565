import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  reset,
  emailOTPTrigger,
  emailOTPVerify,
} from '../features/auth/authSlice'
import { toast } from 'react-toastify'

function VerifyEmail() {
  const [emailText, setEmailText] = useState('')
  const [securityCodeText, setSecurityCodeText] = useState('')
  const [submittingEmail, setSubmittingEmail] = useState(true)
  const [submittingCode, setSubmittingCode] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, otp_token, isError, isSuccess, message, otpSuccess } =
    useSelector((state) => state.auth)

  useEffect(() => {
    if (isError) {
      console.log(message)
      toast.error('Invalid Code')
      setSubmittingEmail(true)
      setSubmittingCode(false)
      dispatch(reset())
    }

    if (isSuccess && currentStep === 1) {
      setCurrentStep(2)
      dispatch(reset())
    }

    if (otpSuccess) {
      toast.success('Success!')
      dispatch(reset())
      navigate('/verifysms')
    }
  }, [currentStep, navigate, isSuccess, isError, message, dispatch, otpSuccess])

  useEffect(() => {
    if (!user) {
      return navigate('/connectwallet')
    }
    if (!user.email_verified) {
      return navigate('/verifyemail')
    }
    if (!user.phone_verified) {
      return navigate('/verifysms')
    }

    if (user.backup_address === '') {
      return navigate('/backupwallet')
    }

    if (!user.totp_verified) {
      return navigate('/authenticator')
    }

    // add redirect for non paid or paid plan expired
  }, [user, navigate])

  const handleEmailTextInput = (e) => {
    setEmailText(e.target.value)
    setSubmittingEmail(false)
  }

  const handleSecurityCodeInput = (e) => {
    setSecurityCodeText(e.target.value)
    setSubmittingCode(false)
  }

  const submitEmail = async (e) => {
    e.preventDefault()
    if (emailText === '') {
      alert('Please Input An Email')
      setSubmittingEmail(true)
      return
    }

    try {
      dispatch(emailOTPTrigger({ data: emailText }))
    } catch (error) {
      toast.error(error)
    }
  }

  const verifySecurityCode = async (e) => {
    e.preventDefault()
    if (securityCodeText === '') {
      toast.error('Please Input A Security Code')
      setSubmittingCode(true)
      return
    }

    try {
      // dispatch API call to verify
      dispatch(emailOTPVerify({ code: securityCodeText, otp_token: otp_token }))
    } catch (error) {
      toast.error(error)
    }
  }

  const resendCode = async () => {
    try {
      dispatch(emailOTPTrigger({ data: emailText }))
      toast.success('Please Check Your Email Again')
    } catch (error) {
      toast.error(error)
    }
  }

  return (
    <div className='container'>
      {/* render this on step 1 */}
      {currentStep === 1 && (
        <div className='row'>
          <div className='col-3 hidden-xs hidden-sm'></div>
          <div className='col-12 col-md-6 col-sm-12'>
            <h1 className='text-center canary-h1'>
              Where should we send alerts?
            </h1>
            <p className='text-left'>
              We want to notify you of all your wallet activity. Where should we
              send alerts to?
            </p>
            <form onSubmit={submitEmail}>
              <div className='mb-3'>
                <label htmlFor='emailAlert' className='form-label'>
                  Email address
                </label>
                <input
                  onChange={handleEmailTextInput}
                  value={emailText}
                  type='email'
                  className='form-control'
                  id='emailAlert'
                  placeholder='name@example.com'
                />
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  className='btn btn-primary w-100 canary-btn-blue'
                  disabled={submittingEmail ? 'disabled' : null}
                >
                  Next: Verify My Email
                </button>
              </div>
            </form>
          </div>
          <div className='col-3 hidden-xs hidden-sm'></div>
        </div>
      )}

      {/* render this on step 2 to verify */}
      {currentStep === 2 && (
        <div className='row'>
          <div className='col-3 hidden-xs hidden-sm'></div>
          <div className='col-12 col-md-6 col-sm-12'>
            <h3 className='text-center canary-h1'>
              Verify Your Email Address{' '}
            </h3>
            <p className='text-left'>
              We sent a security code to your email address, please enter it
              below
            </p>
            <form onSubmit={verifySecurityCode}>
              <div className='mb-3'>
                <input
                  onChange={handleSecurityCodeInput}
                  value={securityCodeText}
                  type='input'
                  className='form-control'
                  id='securityCode'
                  placeholder='Security Code'
                />
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  className='btn btn-primary w-100 canary-btn-blue'
                  disabled={submittingCode ? 'disabled' : null}
                >
                  Confirm Email
                </button>
              </div>
              <p onClick={resendCode} className='text-end resend-code'>
                Resend Code
              </p>
            </form>
          </div>
          <div className='col-3 hidden-xs hidden-sm'></div>
        </div>
      )}
    </div>
  )
}

export default VerifyEmail
