import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import QRCode from 'react-qr-code'
import { useNetworkChain } from '../hooks/useNetworkChain'
import { reset, TOTPVerify } from '../features/auth/authSlice'

function AuthenticatorVerify() {
  const [securityCodeText, setSecurityCodeText] = useState('')
  const [setupCodeValid, setSetupCodeValid] = useState(true)
  const [totp_uri, setTotp_uri] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [otp_token, setOtp_token] = useState('')
  const { chain } = useNetworkChain()

  const { user, auth_token, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSecurityCodeInput = (e) => {
    setSecurityCodeText(e.target.value)
    setSetupCodeValid(false)
  }

  const TOTPTrigger = async () => {
    try {
      const auth = {
        token: auth_token,
      }
      const payload = { auth }
      console.log('TOTP TRIGGER')

      let API_URL

      if (process.env.NODE_ENV === 'production') {
        API_URL = 'https://be-api.lindywallet.com/api/v1/user/self/totp'
      } else {
        API_URL = 'http://localhost:3000/api/v1/user/self/totp'
      }
      const result = await axios.post(API_URL, payload)

      setTotp_uri(result.data.totp_uri)
      setOtp_token(result.data.otp_token)
      OTPInput()
      setIsLoading(false)
      OTPInput()
      console.log(result)
    } catch (error) {
      toast.error('Error Loading TOTP Code')
    }
  }

  useEffect(() => {
    if (auth_token) {
      TOTPTrigger()
    }
  }, [auth_token])

  useEffect(() => {
    if (isError) {
      console.log(message)
      toast.error(message)
      dispatch(reset())
    }

    if (isSuccess) {
      dispatch(reset())
      navigate('/explainer')
    }

    if (!isLoading) {
      OTPInput()
    }
  }, [isError, isSuccess, message, navigate, dispatch, isLoading])

  useEffect(() => {
    if (!user) {
      return navigate('/connectwallet')
    }
    if (!user.email_verified) {
      return navigate('/verifyemail')
    }
    if (!user.phone_verified) {
      return navigate('/verifysms')
    }

    if (user.backup_address === '') {
      return navigate('/backupwallet')
    }

    if (!user.totp_verified) {
      return navigate('/authenticator')
    }

    // add redirect for non paid or paid plan expired
  }, [user, navigate])

  const onSubmit = (e) => {
    e.preventDefault()
    const inputs = document.querySelectorAll('#otp > *[id]')
    let compiledOtp = ''
    for (let i = 0; i < inputs.length; i++) {
      compiledOtp += inputs[i].value
    }
    console.log(compiledOtp)
    console.log('compiled...')
    setSecurityCodeText(compiledOtp)
    dispatch(TOTPVerify({ code: compiledOtp, otp_token: otp_token }))
  }
  function OTPInput() {
    const inputs = document.querySelectorAll('#otp > *[id]')
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('keydown', function (event) {
        if (event.key === 'Backspace') {
          inputs[i].value = ''
          if (i !== 0) inputs[i - 1].focus()
          setSetupCodeValid(true)
        } else {
          if (i === inputs.length - 1 && inputs[i].value !== '') {
            console.log('done 1')
            setSetupCodeValid(false)
            return true
          } else if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key
            if (i !== inputs.length - 1) inputs[i + 1].focus()
            console.log('done 2')
            console.log(i)
            if (i === 5) {
              setSetupCodeValid(false)
            }
            event.preventDefault()
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            inputs[i].value = String.fromCharCode(event.keyCode)
            if (i !== inputs.length - 1) inputs[i + 1].focus()
            console.log('done 3')
            event.preventDefault()
          }
        }
      })
    }
  }
  OTPInput()

  if (isLoading) {
    return (
      <div className='container'>
        <div className='row'>
          <h1 className='text-center'>Loading...</h1>
        </div>
      </div>
    )
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-3 hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <h1 className='text-center canary-h1'>Multi Factor Authentication</h1>
          <p className='text-left'>
            Add an extra layer of security. You'll need this 2FA code every time
            when you need to change information in your account.
          </p>
          <h1 className='text-center canary-h1'>Add 2FA Authentication App</h1>
          <ul className='fa-instructions'>
            <li>
              1. <b>Open</b> your authentication app and scan this QR code.
            </li>
            <li>
              2. <b>Add</b> an account withen the app, and scan the barcode
              below
            </li>
          </ul>

          <p className='text-center'>
            <QRCode value={totp_uri} size={200} />
          </p>
          <ul className='fa-instructions'>
            <li>
              3. <b>Enter OTP.</b> After you've scanned the barcode, enter the
              OTP generated by the app.
            </li>
          </ul>
          <form onSubmit={onSubmit}>
            <div className='mb-6 text-center'>
              <div
                id='otp'
                className='d-flex justify-content-center text-center'
              >
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='first'
                  maxLength='1'
                />
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='second'
                  maxLength='1'
                />
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='third'
                  maxLength='1'
                />
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='fourth'
                  maxLength='1'
                />
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='fifth'
                  maxLength='1'
                />
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='sixth'
                  maxLength='1'
                />
              </div>
              {/* <input
                onChange={handleSecurityCodeInput}
                value={securityCodeText}
                type='text'
                className='form-control'
                id='code'
              /> */}
            </div>
            <div className='text-center'>
              <button
                type='submit'
                className='btn btn-primary w-100 canary-btn-blue'
                disabled={setupCodeValid}
              >
                Next: Add More Security
              </button>
            </div>
          </form>
        </div>
        <div className='container'>
          <div className='text-center'>
            <p>
              You are running this application in <b>{process.env.NODE_ENV}</b>{' '}
              mode.
            </p>
            <p>You are currently on the {chain} network</p>
          </div>
        </div>
        <div className='col-3 hidden-xs hidden-sm'></div>
      </div>
    </div>
  )
}

export default AuthenticatorVerify
