import { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { reset, logout } from '../features/auth/authSlice'
import { toast } from 'react-toastify'

function Navbar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)

  const maskCharacter = (str, mask, n = 1) => {
    // Slice the string and replace with
    // mask then add remaining string
    return ('' + str).slice(0, -n).replace(/./g, mask) + ('' + str).slice(-n)
  }

  const disconnetWallet = async () => {
    console.log('disconnect')
    document.querySelectorAll('.navbar-toggler')[0].click()
    toast.success('You have succesfully logged out')
    dispatch(reset())
    dispatch(logout())
    navigate('/connectwallet')
  }

  const closeOrOpenMenu = () => {
    document.querySelectorAll('.navbar-toggler')[0].click()
  }

  return (
    <div className='mb-2'>
      <nav className='canary-nav-bar navbar navbar-light'>
        <div className='container-fluid'>
          <Link className='navbar-brand' to='/'></Link>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarNav'
            aria-controls='navbarNav'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='canary-navbar-toggler-icon navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarNav'>
            {user ? (
              <>
                <ul className='navbar-nav'>
                  <li className='nav-item'>
                    <Link
                      onClick={closeOrOpenMenu}
                      to='/dashboard'
                      className='nav-link'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='bi bi-arrow-left'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fillRule='evenodd'
                          d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'
                        />
                      </svg>{' '}
                      Back To Dashboard
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to='/reauth/email'
                      className='nav-link'
                      onClick={closeOrOpenMenu}
                    >
                      <span className='d-block'>Update Email Address</span>
                      <span className='d-inline-block nav-link-user-setting'>
                        {user.email.slice(0, 1)}
                        {maskCharacter(user.email.split('@')[0], '*')}@
                        {maskCharacter(user.email.split('@')[1], '*', 4)}
                      </span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='float-end'
                        viewBox='0 0 16 16'
                      >
                        <path
                          d='M1.29 9.88027L5.17 6.00027L1.29 2.12027C0.899998 1.73027 0.899998 1.10027 1.29 0.710273C1.68 0.320272 2.31 0.320272 2.7 0.710273L7.29 5.30027C7.68 5.69027 7.68 6.32027 7.29 6.71027L2.7 11.3003C2.31 11.6903 1.68 11.6903 1.29 11.3003C0.909998 10.9103 0.899998 10.2703 1.29 9.88027Z'
                          fill='white'
                        />
                      </svg>
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to='/reauth/sms'
                      className='nav-link'
                      onClick={closeOrOpenMenu}
                    >
                      <span className='d-block'>Update Phone Number</span>
                      <span className='d-inline-block nav-link-user-setting'>
                        {maskCharacter(user.phone, '*', 4)}
                      </span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='float-end'
                        viewBox='0 0 16 16'
                      >
                        <path
                          d='M1.29 9.88027L5.17 6.00027L1.29 2.12027C0.899998 1.73027 0.899998 1.10027 1.29 0.710273C1.68 0.320272 2.31 0.320272 2.7 0.710273L7.29 5.30027C7.68 5.69027 7.68 6.32027 7.29 6.71027L2.7 11.3003C2.31 11.6903 1.68 11.6903 1.29 11.3003C0.909998 10.9103 0.899998 10.2703 1.29 9.88027Z'
                          fill='white'
                        />
                      </svg>
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to='/reauth/wallet'
                      className='nav-link'
                      onClick={closeOrOpenMenu}
                    >
                      <span className='d-block'>Update Backup Wallet</span>
                      <span className='d-inline-block nav-link-user-setting'>
                        {maskCharacter(user.backup_address, '*', 4)}
                      </span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='16'
                        height='16'
                        fill='currentColor'
                        className='float-end'
                        viewBox='0 0 16 16'
                      >
                        <path
                          d='M1.29 9.88027L5.17 6.00027L1.29 2.12027C0.899998 1.73027 0.899998 1.10027 1.29 0.710273C1.68 0.320272 2.31 0.320272 2.7 0.710273L7.29 5.30027C7.68 5.69027 7.68 6.32027 7.29 6.71027L2.7 11.3003C2.31 11.6903 1.68 11.6903 1.29 11.3003C0.909998 10.9103 0.899998 10.2703 1.29 9.88027Z'
                          fill='white'
                        />
                      </svg>
                    </Link>
                  </li>
                  <li className='nav-item nav-ui-seperator'>
                    <Link
                      className='nav-link'
                      to='/managesub'
                      onClick={closeOrOpenMenu}
                    >
                      Manage Subscription
                    </Link>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='#'>
                      Privacy
                    </a>
                  </li>

                  <li className='nav-item'>
                    <a className='nav-link' href='#'>
                      Terms Of Service
                    </a>
                  </li>
                </ul>
                <div
                  className='nav-wallet text-center'
                  onClick={disconnetWallet}
                >
                  Disconnect Wallet
                </div>{' '}
              </>
            ) : (
              <>
                <div className='nav-wallet text-center'>
                  <Link
                    to='/connectwallet'
                    onClick={() => {
                      document.querySelectorAll('.navbar-toggler')[0].click()
                    }}
                  >
                    Connect Wallet
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar
