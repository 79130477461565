import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { reset, login } from '../features/auth/authSlice'
import Spinner from './Spinner'

function SignIn() {
  const [securityCodeText, setSecurityCodeText] = useState('')
  const [setupCodeValid, setSetupCodeValid] = useState(true)
  // create a variable to store the public wallet
  const [currentAccount, setCurrentAccount] = useState('')

  const { user, isError, isSuccess, message, isLoading } = useSelector(
    (state) => state.auth
  )

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const checkIfWalletIsConnected = async () => {
    try {
      const { ethereum } = window

      if (!ethereum) {
        console.log('You need a Metamask Wallet')
        return
      } else {
        console.log('You have a metamask wallet')
      }

      const accounts = await ethereum.request({ method: 'eth_accounts' })

      console.log(accounts)

      if (accounts.length !== 0) {
        // this means we have an account that is authorized on our app
        // we need to check where they are in the steps as well
        const account = accounts[0]
        setCurrentAccount(account)
      } else {
        // we should delete the local storage
        localStorage.removeItem('user')
        console.log('No Authorized Accounts Found')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    checkIfWalletIsConnected()
    window.ethereum.on('accountsChanged', function (accounts) {
      // Time to reload your interface with accounts[0]!
      console.log('account changed', accounts)
      window.location.reload()
    })

    window.ethereum.on('chainChanged', function (networkId) {
      // Time to reload your interface with the new networkId
      console.log('network changed', networkId)
      window.location.reload()
    })
  }, [])

  useEffect(() => {
    OTPInput()
    if (isError) {
      console.log(message)
      toast.error('Invalid 2FA Code')
      dispatch(reset())
    }

    if (isSuccess) {
      dispatch(reset())
      console.log('sucess......')
      toast.success('Welcome Back!')
      navigate('/dashboard')
    }
  }, [isError, isSuccess, message, navigate, dispatch, isLoading])

  if (isLoading) {
    return <Spinner />
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const inputs = document.querySelectorAll('#otp > *[id]')
    let compiledOtp = ''
    for (let i = 0; i < inputs.length; i++) {
      compiledOtp += inputs[i].value
    }
    console.log(compiledOtp)
    console.log('compiled...')
    setSecurityCodeText(compiledOtp)

    //     {
    //     "wallet_type": 0,
    //     "account_id": "0xab282590375f4ce7d3ffed3a0d0e36cc5f9ff124",
    //     "totp_code": "719555"
    // }

    console.log({
      wallet_type: 0,
      account_id: currentAccount,
      totp_code: compiledOtp,
    })

    dispatch(
      login({
        wallet_type: 0,
        account_id: currentAccount,
        totp_code: compiledOtp,
      })
    )
  }

  function OTPInput() {
    const inputs = document.querySelectorAll('#otp > *[id]')
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('keydown', function (event) {
        if (event.key === 'Backspace') {
          inputs[i].value = ''
          if (i !== 0) inputs[i - 1].focus()
          setSetupCodeValid(true)
        } else {
          if (i === inputs.length - 1 && inputs[i].value !== '') {
            console.log('done 1')
            setSetupCodeValid(false)
            return true
          } else if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key
            if (i !== inputs.length - 1) inputs[i + 1].focus()
            console.log('done 2')
            console.log(i)
            if (i === 5) {
              setSetupCodeValid(false)
            }
            event.preventDefault()
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            inputs[i].value = String.fromCharCode(event.keyCode)
            if (i !== inputs.length - 1) inputs[i + 1].focus()
            console.log('done 3')
            event.preventDefault()
          }
        }
      })
    }
  }
  OTPInput()

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-3 hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <h1 className='text-center canary-h1'>Welcome Back To Canary</h1>
          <p className='text-center'>
            Current Connected Account: <b>{currentAccount}</b>
          </p>
          <p className='text-center'>Please enter your 2FA code below</p>

          <form onSubmit={onSubmit}>
            <div className='mb-6 text-center'>
              <div
                id='otp'
                className='d-flex justify-content-center text-center'
              >
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='first'
                  maxLength='1'
                />
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='second'
                  maxLength='1'
                />
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='third'
                  maxLength='1'
                />
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='fourth'
                  maxLength='1'
                />
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='fifth'
                  maxLength='1'
                />
                <input
                  className='m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline'
                  type='text'
                  id='sixth'
                  maxLength='1'
                />
              </div>
            </div>
            <div className='text-center'>
              <button
                type='submit'
                className='btn btn-primary w-100 canary-btn-blue'
                disabled={setupCodeValid}
              >
                Sign In
              </button>
            </div>
          </form>
        </div>

        <div className='col-3 hidden-xs hidden-sm'></div>
      </div>
    </div>
  )
}

export default SignIn
