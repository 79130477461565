import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { reset, smsOTPTrigger, smsOTPVerify } from '../features/auth/authSlice'
import { toast } from 'react-toastify'

function VerifySMS() {
  const [smsText, setsmsText] = useState('')
  const [securityCodeText, setSecurityCodeText] = useState('')
  const [submittingSMS, setSubmittingSMS] = useState(true)
  const [submittingCode, setSubmittingCode] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { user, otp_token, isError, isSuccess, message, otpSuccess } =
    useSelector((state) => state.auth)
  const checkIfWalletIsConnected = async () => {
    try {
      const { ethereum } = window

      if (!ethereum) {
        console.log('You need a Metamask Wallet')
        return
      } else {
        console.log('You have a metamask wallet')
      }

      const accounts = await ethereum.request({ method: 'eth_accounts' })

      if (accounts.length !== 0) {
        // this means we have an account that is authorized on our app
      } else {
        // we should delete the local storage
        localStorage.removeItem('user')
        toast.error('Your Wallet Is Not Authorized')
        navigate('/connectwallet')
        console.log('No Authorized Accounts Found')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    checkIfWalletIsConnected()
  }, [])

  useEffect(() => {
    if (!user) {
      return navigate('/connectwallet')
    }
    if (!user.email_verified) {
      return navigate('/verifyemail')
    }
    if (!user.phone_verified) {
      return navigate('/verifysms')
    }

    if (user.backup_address === '') {
      return navigate('/backupwallet')
    }

    if (!user.totp_verified) {
      return navigate('/authenticator')
    }

    // add redirect for non paid or paid plan expired
  }, [user, navigate])

  useEffect(() => {
    if (isError) {
      console.log(message)
      toast.error('Invalid code or not a valid phone number')
      dispatch(reset())
    }

    if (isSuccess && currentStep === 1) {
      setCurrentStep(2)
      dispatch(reset())
    }

    if (otpSuccess) {
      dispatch(reset())
      navigate('/backupwallet')
    }
  }, [
    currentStep,
    navigate,
    isSuccess,
    isError,
    message,
    dispatch,
    submittingSMS,
    otpSuccess,
  ])

  const handleSMSTextInput = (e) => {
    if (e.target.value === '') {
      setsmsText('')
      setSubmittingSMS(true)
    } else {
      setsmsText(e.target.value)
      setSubmittingSMS(false)
    }
  }

  const handleSecurityCodeInput = (e) => {
    if (e.target.value === '') {
      setSecurityCodeText('')
      setSubmittingCode(true)
    } else {
      setSecurityCodeText(e.target.value)
      setSubmittingCode(false)
    }
  }

  const submitPhone = async (e) => {
    e.preventDefault()
    if (smsText === '') {
      toast.error('Please Input Your Phone Number')
      return
    }

    // send the SMS
    dispatch(smsOTPTrigger({ data: smsText }))
  }

  const verifySecurityCode = async (e) => {
    e.preventDefault()
    if (securityCodeText === '') {
      toast.error('Please Input A Security Code')
      return
    }

    dispatch(smsOTPVerify({ code: securityCodeText, otp_token: otp_token }))
  }

  const resendCode = async () => {
    try {
      dispatch(smsOTPTrigger({ data: smsText }))
      toast.success('Please Check Your Text Again')
    } catch (error) {
      toast.error(error)
    }
  }

  return (
    <div className='container'>
      {/* render this on step 1 */}
      {currentStep === 1 && (
        <div className='row'>
          <div className='col-3 hidden-xs hidden-sm'></div>
          <div className='col-12 col-md-6 col-sm-12'>
            <h1 className='text-center canary-h1'>
              Where should we send SMS alerts?
            </h1>
            <p className='text-left'>
              We want to notify you of all your wallet activity. Where should we
              send alerts to?
            </p>
            <form onSubmit={submitPhone}>
              <div className='mb-3'>
                {/* <label htmlFor='smsAlert' className='form-label'>
                  Phone Number
                </label> */}
                <input
                  onChange={handleSMSTextInput}
                  value={smsText}
                  type='text'
                  className='form-control'
                  id='smsAlert'
                  placeholder='+12125561234'
                />
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  className='btn btn-primary w-100 canary-btn-blue'
                  disabled={submittingSMS ? 'disabled' : null}
                >
                  Next: Verify My Phone
                </button>
              </div>
            </form>
          </div>
          <div className='col-3 hidden-xs hidden-sm'></div>
        </div>
      )}

      {/* render this on step 2 to verify */}
      {currentStep === 2 && (
        <div className='row'>
          <div className='col-3 hidden-xs hidden-sm'></div>
          <div className='col-12 col-md-6 col-sm-12'>
            <h3 className='text-center canary-h1'>Verify Your Phone Number</h3>
            <p className='text-left'>
              Please check your text messages and enter the code below.
            </p>
            <form onSubmit={verifySecurityCode}>
              <div className='mb-3'>
                <input
                  onChange={handleSecurityCodeInput}
                  value={securityCodeText}
                  type='input'
                  className='form-control'
                  id='securityCode'
                  placeholder='Text Security Code'
                />
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  className='btn btn-primary w-100 canary-btn-blue'
                  disabled={submittingCode ? 'disabled' : null}
                >
                  Next: Add Backup Wallet
                </button>
              </div>
              <p onClick={resendCode} className='text-end resend-code'>
                Resend Code
              </p>
            </form>
          </div>
          <div className='col-3 hidden-xs hidden-sm'></div>
        </div>
      )}
    </div>
  )
}

export default VerifySMS
