import { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'

function PaymentThankYou() {
  const navigate = useNavigate()
  useEffect(() => {
    setTimeout(() => {
      navigate('/dashboard')
    }, 8000)
  }, [navigate])

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-3 hidden-md hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <h1 className='text-center canary-welcome-header'>
            Thank You For Subscribing
          </h1>
          <h1 className='text-center canary-h1'>
            You will receive an email will your payment details shortly
          </h1>
          <p className='text-center'>
            You Will Be Redirected To Your{' '}
            <Link to='/dashboard'>Dashboard</Link>
          </p>
        </div>
        <div className='col-3 hidden-xs hidden-sm'></div>
      </div>
    </div>
  )
}

export default PaymentThankYou
