import { useNavigate } from 'react-router-dom'

function ManageSubscriptionDowngrade() {
  const navigate = useNavigate()

  const doNotDowngrade = () => {
    navigate('/dashboard')
  }

  const cancelPlan = () => {
    navigate('/managesubcancel')
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-3 hidden-md hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <div className='manageplan-border'>
            <h1 className='text-center price-h1'> ⚠️ Wait! ⚠️ </h1>
            <p className='price-ul-header text-center'>
              Are you sure you want to downgrade?
            </p>

            <div className='manageplan-box text-left'>
              You will lose ALL access to:
              <ul className='manageplan-info'>
                <li>
                  <b>Real Time SMS Notifcations</b> to alert you when any
                  transactions occur
                </li>
                <li>
                  <b>Eject feature</b> to quickly save your assets and send them
                  to your secure backup wallet
                </li>
              </ul>
            </div>

            <div className='manageplan-box text-left'>
              You will only have access to:
              <ul className='manageplan-info'>
                <li>
                  <b>Email Notifications</b>
                </li>
              </ul>
            </div>
            <div className='mb-3'>
              <div className='text-center'>
                <button
                  type='submit'
                  onClick={doNotDowngrade}
                  className='btn btn-primary w-100 canary-btn-blue'
                >
                  Do NOT Downgrade My Plan
                </button>
                <p className='manageplan-text-error' onClick={cancelPlan}>
                  Continue Downgrading To Free Plan
                </p>
              </div>
            </div>
          </div>
          <div className='col-3 hidden-xs hidden-sm'></div>
        </div>
      </div>
    </div>
  )
}

export default ManageSubscriptionDowngrade
