function CryptoAssetList({ assetList }) {
  const isEmpty = Object.keys(assetList).length === 0

  if (isEmpty) {
    return (
      <div>
        <p className='small text-muted'>
          No Assets Found In Your Backup Wallet
        </p>
      </div>
    )
  }

  return (
    <div className='container mt-2'>
      <p className='small text-muted'>Your backup wallet's crypto assets</p>
      <ul className='assetlist-ul'>
        {Object.keys(assetList).map((key, index) => (
          <li id={index} key={index}>
            <span className='token-logo'>
              <img src={assetList[key]['contractImage']} />
            </span>
            <span className='font-weight-bold text-capitalize'>
              <b>{assetList[key].tokenSymbol}</b> : {assetList[key]['value']}
            </span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default CryptoAssetList
