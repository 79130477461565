import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  registerCryptoCurrencyAPI,
  reset,
  registerAccount,
  updateUser,
} from '../features/auth/authSlice'

function ConfirmDetails() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  )

  const { phone, email, backup_address, account_id } = user

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    // Redirect when saved
    if (isSuccess) {
      //navigate('/')
      toast.success('Succesfully Saved')
      dispatch(reset())
      navigate('/selectplan')
    }

    dispatch(reset())
  }, [isError, isSuccess, user, message, navigate, dispatch])

  useEffect(() => {
    if (!user) {
      return navigate('/connectwallet')
    }
    if (!user.email_verified) {
      return navigate('/verifyemail')
    }
    if (!user.phone_verified) {
      return navigate('/verifysms')
    }

    if (user.backup_address === '') {
      return navigate('/backupwallet')
    }

    if (!user.totp_verified) {
      return navigate('/authenticator')
    }

    // add redirect for non paid or paid plan expired
  }, [user, navigate])

  const onSubmit = async (e) => {
    e.preventDefault()
    dispatch(reset())
    navigate('/selectplan')
  }

  return (
    <div className='container confirm-details'>
      <div className='row'>
        <div className='col-3 hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <h1 className='text-center canary-h1'>Confirm Your Details</h1>
          <form onSubmit={onSubmit}>
            <div className='mb-3'>
              <label className='form-label'>Your Primary Wallet Address</label>
              <input
                value={account_id}
                type='text'
                className='form-control'
                disabled
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Your Backup Wallet Address</label>
              <input
                value={backup_address}
                type='text'
                className='form-control'
                disabled
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Your Email Address</label>
              <input
                value={email}
                type='text'
                className='form-control'
                disabled
              />
            </div>
            <div className='mb-3'>
              <label className='form-label'>Your Phone Number</label>
              <input
                type='text'
                className='form-control'
                disabled
                value={phone}
              />
            </div>
            <div className='mb-3'>
              <div className='text-center'>
                <button
                  type='submit'
                  className='btn btn-primary w-100 canary-btn-blue'
                >
                  Secure My Wallet!
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className='col-3 hidden-xs hidden-sm'></div>
      </div>
    </div>
  )
}

export default ConfirmDetails
