import { useNavigate } from 'react-router-dom'

function ManageSubscription() {
  const navigate = useNavigate()

  const downgradePlan = () => {
    navigate('/managesubdowngrade')
  }

  const cancelPlan = () => {
    navigate('/managesubcancel')
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-3 hidden-md hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <p className='price-ul-header text-center'>
            Your $9.99/mo plan includes
          </p>

          <div className='manageplan-box text-left'>
            <ul className='manageplan-info'>
              <li>
                <b>Real Time SMS Notifcations</b> to alert you when any
                transactions occur
              </li>
              <li>
                <b>Email Notifications</b> in case your wallet is being
                compromised
              </li>
              <li>
                <b>Eject feature</b> to quickly save your assets and send them
                to your secure backup wallet
              </li>
            </ul>
          </div>
          <div className='mb-3'>
            <div className='text-center'>
              <button
                type='submit'
                onClick={downgradePlan}
                className='btn btn-primary w-100 canary-btn-blue-ghost'
              >
                Downgrade to Free Plan
              </button>
              <p className='manageplan-text-error' onClick={cancelPlan}>
                Cancel all services
              </p>
            </div>
          </div>
        </div>
        <div className='col-3 hidden-xs hidden-sm'></div>
      </div>
    </div>
  )
}

export default ManageSubscription
