import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

function ManageSubscriptionCancel() {
  const navigate = useNavigate()

  const doNotDowngrade = () => {
    navigate('/dashboard')
  }

  const cancelPlan = () => {
    let STRIPE_URL = ''
    if (process.env.NODE_ENV === 'production') {
      STRIPE_URL = 'https://billing.stripe.com/p/login/9AQeVN1419BZbhm144'
    } else {
      STRIPE_URL = 'https://billing.stripe.com/p/login/test_eVadUr1gSb5Xat2eUU'
    }
    url_redirect(STRIPE_URL)
  }

  function url_redirect(url) {
    var X = setTimeout(function () {
      window.location.replace(url)
      return true
    }, 300)

    if ((window.location = url)) {
      clearTimeout(X)
      return true
    } else {
      if ((window.location.href = url)) {
        clearTimeout(X)
        return true
      } else {
        clearTimeout(X)
        window.location.replace(url)
        return true
      }
    }
    return false
  }

  return (
    <div className='container'>
      <div className='row '>
        <div className='col-3 hidden-md hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <div className='manageplan-border'>
            <h1 className='text-center price-h1'> ⚠️ Wait! ⚠️ </h1>
            <p className='price-ul-header text-center'>
              Are you sure you want to cancel?
            </p>

            <div className='manageplan-box text-left'>
              You will lose ALL access to:
              <ul className='manageplan-info'>
                <li>
                  <b>Real Time SMS Notifcations</b> to alert you when any
                  transactions occur
                </li>
                <li>
                  <b>Email Notifications</b> in case your wallet is being
                  compromised
                </li>
                <li>
                  <b>Eject feature</b> to quickly save your assets and send them
                  to your secure backup wallet
                </li>
              </ul>
              <p className='manageplan-red-error'>
                Your Wallet will <b>no longer be protected</b>
              </p>
            </div>

            <div className='mb-3'>
              <div className='text-center'>
                <button
                  type='submit'
                  onClick={doNotDowngrade}
                  className='btn btn-primary w-100 canary-btn-blue'
                >
                  Do NOT Downgrade My Plan
                </button>
                <p className='manageplan-text-error' onClick={cancelPlan}>
                  Continue Cancelling Plan
                </p>
              </div>
            </div>
          </div>
          <div className='col-3 hidden-xs hidden-sm'></div>
        </div>
      </div>
    </div>
  )
}

export default ManageSubscriptionCancel
