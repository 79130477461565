import axios from 'axios'
import { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { toast } from 'react-toastify'
import CryptoAssetList from './CryptoAssetList'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { reset, updateBackupWallet } from '../features/auth/authSlice'
import contractTokens from '../ContractTokens/tokens'

function EditBackupWallet() {
  const { user, isError, isLoading, isSuccess, message } = useSelector(
    (state) => state.auth
  )
  const [backupWalletAddress, setBackupWalletAddress] = useState('')
  const [backupWalletValid, setBackupwalletValid] = useState(true)
  const [tokensInWallet, setTokensInWallet] = useState({})

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const search = (nameKey, myArray) => {
    for (var i = 0; i < myArray.length; i++) {
      if (myArray[i].symbol === nameKey) {
        return myArray[i]
      }
    }
  }

  // The minimum ABI to get ERC20 Token balance
  let minABI = [
    // balanceOf
    {
      constant: true,
      inputs: [{ name: '_owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: 'balance', type: 'uint256' }],
      type: 'function',
    },
    // decimals
    {
      constant: true,
      inputs: [],
      name: 'decimals',
      outputs: [{ name: '', type: 'uint8' }],
      type: 'function',
    },
  ]

  useEffect(() => {
    if (isSuccess) {
      toast.info('Backup Wallet Updated')
      dispatch(reset())
      navigate('/dashboard')
    }
  })

  const getETHBalanceFromWallet = async (address) => {
    try {
      const { ethereum } = window

      const provider = new ethers.providers.Web3Provider(ethereum)
      const balance = await provider.getBalance(address)
      const ethValue = ethers.utils.formatEther(balance)
      console.log('ETH Balance:', balance)
      console.log('ETH Token Balance Formated:', ethValue)
      const ethToken = {
        ETH: {
          value: ethValue,
          tokenSymbol: 'ETH',
          contractAddress: '',
          contractImage: './assets/ethlogo.png',
        },
      }

      setTokensInWallet((prevState) => {
        return {
          ...prevState,
          ...ethToken,
        }
      })
      setBackupwalletValid(false)
    } catch (error) {
      console.log(error)
    }
  }

  const saveBackupWallet = async (e) => {
    e.preventDefault()

    dispatch(
      updateBackupWallet({
        backup_address: backupWalletAddress,
        paid_plan: 0,
      })
    )
  }

  const getContractsFromAddressWithEtherscan = async (address) => {
    try {
      const { ethereum } = window
      let API_URL = ''

      const network = ethereum.networkVersion
      console.log(ethereum.networkVersion)
      switch (network) {
        case '1':
          API_URL = `https://api.etherscan.io/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=19IBE8HT51DJH2C7KP8227BKEE175XU8SC`
          break
        case '4':
          API_URL = `https://api-rinkeby.etherscan.io/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=19IBE8HT51DJH2C7KP8227BKEE175XU8SC`
          break
        case '5':
          API_URL = `https://api-goerli.etherscan.io/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=19IBE8HT51DJH2C7KP8227BKEE175XU8SC`
          break
        default:
          toast.error('API Error: Refresh the browser')
          break
      }

      const tokensObj = {}
      const tokens = []
      const tokenNames = []
      const tokenSymbols = []
      const tokenDecimals = []

      const response = await axios.get(API_URL)

      const results = response.data['result']
      console.log(results)
      for (let result of results) {
        if (!tokens.includes(result.contractAddress)) {
          tokens.push(result.contractAddress)
          tokenNames.push(result.tokenName)
          tokenDecimals.push(result.tokenDecimal)
          tokenSymbols.push(result.tokenSymbol)
        }
      }
      tokensObj.tokenNames = tokenNames
      tokensObj.tokenSymbols = tokenSymbols
      tokensObj.contractAddresses = tokens
      tokensObj.decimals = tokenDecimals
      console.log(tokensObj)
      return tokensObj
    } catch (e) {
      console.log(e)
    }
  }

  const getERC20BalanceFromWallet = async (address, tokensObj) => {
    try {
      const { ethereum } = window

      const newTokens = {}
      const tokens = tokensObj.contractAddresses
      const tokenNames = tokensObj.tokenNames
      const tokenSymbols = tokensObj.tokenSymbols
      console.log('tokensSymbols', tokenSymbols)

      for (const key in tokens) {
        if (tokens.hasOwnProperty(key)) {
          const provider = new ethers.providers.Web3Provider(ethereum)
          const contractAddress = tokens[key]
          const contract = new ethers.Contract(
            contractAddress,
            minABI,
            provider
          )
          const balance = (await contract.balanceOf(address)).toString()
          const ethValue = ethers.utils.formatEther(balance)
          console.log(`${tokenNames[key]} Balance:`, balance)
          console.log(`${tokenNames[key]}  Token Balance Formated:`, ethValue)
          //const newToken = { [key]: ethValue }

          //console.log("new token", newToken)
          const symbol = tokenSymbols[key]

          const tokenSearch = search(symbol, contractTokens.tokens)
          let imageURL

          if (tokenSearch) {
            imageURL = tokenSearch.logoURI
          } else {
            imageURL = './assets/empty-token.png'
          }

          newTokens[tokenNames[key]] = {
            value: ethValue,
            tokenSymbol: tokenSymbols[key],
            contractAddress: contractAddress,
            contractImage: imageURL,
          }
        }
      }

      setTokensInWallet((prevState) => {
        return {
          ...prevState,
          ...newTokens,
        }
      })

      // const balance = (await contract.balanceOf(account)).toString();
      // const ethValue = ethers.utils.formatEther(balance);
      // console.log("Token Balance:", balance)
      // console.log("Token Balance Formatedd:", ethValue)
      console.log('tokens in wallet')
      console.log(tokensInWallet)
    } catch (error) {
      console.log(error)
      toast.error('Error: Invalid address or ENS name')
    }
  }

  const handleKeyDown = (event) => {
    console.log('User pressed: ', event.key)

    // console.log(message);

    if (event.key === 'Backspace') {
      // 👇️ your logic here
      console.log('Backspace key pressed ✅')
      setBackupwalletValid(true)
      setTokensInWallet({})
    }
  }

  const handleBackupWalletAddressInput = async (e) => {
    // onchange should check if the address is valid
    // then pull the assets automatically
    if (e.target.value === '') {
      setBackupwalletValid(true)
      setTokensInWallet({})
    }
    setBackupWalletAddress(e.target.value)
    const validBackupAddress = ethers.utils.isAddress(e.target.value)
    if (validBackupAddress) {
      //toast.success('Valid ETH Address')

      // get contracts in the address

      await getETHBalanceFromWallet(e.target.value)

      const tokensObj = await getContractsFromAddressWithEtherscan(
        e.target.value
      )
      await getERC20BalanceFromWallet(e.target.value, tokensObj)
    } else {
      //toast.error('Not a Valid ETH Address')
    }
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-3 hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <h1 className='text-center canary-h1'>Edit Your Backup Wallet</h1>
          <p className='text-left'>
            In case of emergencies, where should we transfer your assets to?
          </p>
          <form onSubmit={saveBackupWallet}>
            <div className='mb-1'>
              <input
                onChange={handleBackupWalletAddressInput}
                onKeyDown={handleKeyDown}
                value={backupWalletAddress}
                type='text'
                className='form-control'
                id='emailAlert'
                placeholder='Enter Your Backup ETH Wallet'
              />
            </div>
            <div className='row mb-1'>
              <CryptoAssetList assetList={tokensInWallet} />
            </div>
            <div className='text-center'>
              <button
                type='submit'
                className='btn btn-primary w-100 canary-btn-blue'
                disabled={backupWalletValid}
              >
                Update Backup Wallet
              </button>
            </div>
          </form>
          <div className='col-3 hidden-xs hidden-sm'></div>
        </div>
      </div>
    </div>
  )
}

export default EditBackupWallet
