import './App.css'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About'
import Error from './pages/Error'
import Navbar from './layout/Navbar'
import AuthenticatorVerify from './components/AuthenticatorVerify'
import BackupWallet from './components/BackupWallet'
import VerifyEmail from './components/VerifyEmail'
import ConfirmDetails from './components/ConfirmDetails'
import Pricing from './components/Pricing'
import Dashboard from './pages/Dashboard'
import ConnectWallet from './components/ConnectWallet'
import PrivateRoute from './components/PrivateRoutes'
import VerifySMS from './components/VerifySMS'
import EditBackupWallet from './components/EditBackupWallet'
import ExplainerVid from './components/ExplainerVid'
import PaymentThankYou from './components/PaymentThankYou'
import SignIn from './components/SignIn'
import ManageSubscription from './pages/ManageSubcription'
import ManageSubscriptionDowngrade from './pages/ManageSubscriptionDowngrade'
import ManageSubscriptionCancel from './pages/ManageSubscriptionCancel'
import AuthenticatorReAuth from './components/AuthenticatorReAuth'
import EditEmail from './components/EditEmail'
import EditSMS from './components/EditSMS'
import { useEffect, useState } from 'react'

function App() {
  useEffect(() => {
    if (window.ethereum) {
      const init = async () => {
        // https://docs.metamask.io/guide/ethereum-provider.html#ethereum-enable-deprecated
        // const response = await window.ethereum.enable()
        // console.log(response)
        // await checkNetwork()
      }
      init()
    }
  }, [])

  useEffect(() => {
    window.ethereum.on('chainChanged', (_chainId) => {
      toast.success('chain changed..')
      //log out here and redirect to wallet connect
      console.log(_chainId)
      window.location.reload(true)
    })

    window.ethereum.on('accountsChanged', (_accounts) => {
      console.log('accounts changed..')
      //log out here and redirect to wallet connect
      console.log(_accounts)
      if (window.location.pathname !== '/connectwallet') {
        window.location.reload(true)
      }
    })
  }, [])

  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/connectwallet' element={<ConnectWallet />} />

          <Route path='/selectplan' element={<PrivateRoute />}>
            <Route path='/selectplan' element={<Pricing />} />
          </Route>

          <Route path='/about' element={<PrivateRoute />}>
            <Route path='/about' element={<About />} />
          </Route>
          <Route path='/verifyemail' element={<PrivateRoute />}>
            <Route path='/verifyemail' element={<VerifyEmail />} />
          </Route>

          <Route path='/editemail' element={<PrivateRoute />}>
            <Route path='/editemail' element={<EditEmail />} />
          </Route>

          <Route path='/verifysms' element={<PrivateRoute />}>
            <Route path='/verifysms' element={<VerifySMS />} />
          </Route>

          <Route path='/editsms' element={<PrivateRoute />}>
            <Route path='/editsms' element={<EditSMS />} />
          </Route>

          <Route path='/thankyou' element={<PrivateRoute />}>
            <Route path='/thankyou' element={<PaymentThankYou />} />
          </Route>

          <Route path='/explainer' element={<PrivateRoute />}>
            <Route path='/explainer' element={<ExplainerVid />} />
          </Route>

          <Route path='/authenticator' element={<PrivateRoute />}>
            <Route path='/authenticator' element={<AuthenticatorVerify />} />
          </Route>
          <Route path='/backupwallet' element={<PrivateRoute />}>
            <Route path='/backupwallet' element={<BackupWallet />} />
          </Route>
          <Route path='/editbackupwallet' element={<PrivateRoute />}>
            <Route path='/editbackupwallet' element={<EditBackupWallet />} />
          </Route>

          <Route path='/confirmdetails' element={<PrivateRoute />}>
            <Route path='/confirmdetails' element={<ConfirmDetails />} />
          </Route>
          <Route path='/dashboard' element={<PrivateRoute />}>
            <Route path='/dashboard' element={<Dashboard />} />
          </Route>

          <Route path='/managesub' element={<PrivateRoute />}>
            <Route path='/managesub' element={<ManageSubscription />} />
          </Route>

          <Route path='/managesubdowngrade' element={<PrivateRoute />}>
            <Route
              path='/managesubdowngrade'
              element={<ManageSubscriptionDowngrade />}
            />
          </Route>
          <Route path='/managesubcancel' element={<PrivateRoute />}>
            <Route
              path='/managesubcancel'
              element={<ManageSubscriptionCancel />}
            />
          </Route>

          <Route path='/signin2fa' element={<SignIn />} />
          <Route path='/reauth/:page' element={<PrivateRoute />}>
            <Route path='/reauth/:page' element={<AuthenticatorReAuth />} />
          </Route>
          <Route path='*' element={<Error />} />
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  )
}

export default App
