import { useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { updateStep, updatewalletAddress } from '../features/auth/authSlice'
import { toast } from 'react-toastify'

function Home() {
  // Get user from localstorage
  const { user } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const checkIfWalletIsConnected = async () => {
    try {
      const { ethereum } = window

      if (!ethereum) {
        console.log('You need a Metamask Wallet')
        navigate('/connectwallet')
        return
      } else {
        console.log('You have a metamask wallet')
      }

      const accounts = await ethereum.request({ method: 'eth_accounts' })

      if (accounts.length !== 0) {
        // this means we have an account that is authorized on our app
        // we need to check where they are in the steps as well
        const account = accounts[0]
        checkUserState()
      } else {
        // we should delete the local storage
        localStorage.removeItem('user')
        console.log('No Authorized Accounts Found')
        toast.error('Please Connect Your Wallet')
        navigate('/connectwallet')
      }
    } catch (error) {
      toast.error(error.message)
      console.log(error)
    }
  }

  const checkUserState = async () => {
    console.log(user)
    if (!user) {
      navigate('/connectwallet')
      return
    }
    if (!user.email_verified) {
      navigate('/verifyemail')
      return
    }
    if (!user.phone_verified) {
      navigate('/verifysms')
      return
    }
    if (user.backup_address === '') {
      navigate('/backupwallet')
      return
    }
    if (!user.totp_verified) {
      navigate('/authenticator')
      return
    }

    navigate('/dashboard')
    // if (user.paid_state === 0) {
    //   navigate('/selectplan')
    // }
  }

  useEffect(() => {
    checkIfWalletIsConnected()
  }, [])

  if (user && user.user_state === 5) {
    navigate('/dashboard')
  }

  return <div className='container'> Loading</div>
}

export default Home
