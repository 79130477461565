import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

export const useNetworkChain = () => {
  const [chain, setChain] = useState('')

  const checkNetwork = async () => {
    if (window.ethereum) {
      const network = await window.ethereum.networkVersion
      console.log(window.ethereum.networkVersion)
      switch (network) {
        case '1':
          setChain('Mainnet')
          break
        case '4':
          setChain('Rinkeby')
          break
        case '5':
          setChain('Goerli')
          break
        default:
          toast.error('Error: Refresh the browser')
          break
      }
    }
  }

  useEffect(() => {
    checkNetwork()
  }, [chain])

  return { chain }
}
