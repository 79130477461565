import { Link } from 'react-router-dom'
function Error() {
  return (
    <div className='container dashboard'>
      <div className='row'>
        <div className='col-3 hidden-md hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <h2 className='text-center'>404 - Page Not Found</h2>
          <p className='text-center'>
            Go Back <Link to='/'>Home</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Error
