import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { ethers } from 'ethers'
import { parseEther } from 'ethers/lib/utils'

function Dashboard() {
  const { user } = useSelector((state) => state.auth)

  const { backup_address } = user
  const navigate = useNavigate()

  const [chain, setChain] = useState('')

  const checkNetwork = async () => {
    if (window.ethereum) {
      const chainId = await window.ethereum.request({ method: 'eth_chainId' })
      console.log(chainId)
      switch (chainId) {
        case '0x1':
          return 'Mainnet'
        case '0x4':
          return 'Rinkeby'
        case '0x5':
          return 'Goerli'
        default:
          toast.error('Error: Refresh the browser')
          break
      }
    }
  }

  useEffect(() => {
    const init = async () => {
      const result = await checkNetwork()
      setChain(result)
    }
    if (window.ethereum) {
      init()
    }
  }, [chain])

  // The minimum ABI to get ERC20 Token balance
  let minABI = [
    // balanceOf
    {
      constant: true,
      inputs: [{ name: '_owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: 'balance', type: 'uint256' }],
      type: 'function',
    },
    // decimals
    {
      constant: true,
      inputs: [],
      name: 'decimals',
      outputs: [{ name: '', type: 'uint8' }],
      type: 'function',
    },
  ]

  useEffect(() => {
    if (!user) {
      return navigate('/connectwallet')
    }
    if (!user.email_verified) {
      return navigate('/verifyemail')
    }
    if (!user.phone_verified) {
      return navigate('/verifysms')
    }

    if (user.backup_address === '') {
      return navigate('/backupwallet')
    }

    if (!user.totp_verified) {
      return navigate('/authenticator')
    }

    // add redirect for non paid or paid plan expired
  }, [user, navigate])

  const getContractsFromAddressWithEtherscan = async (address) => {
    try {
      const tokensObj = {}
      const tokens = []
      const tokenNames = []
      const tokenDecimals = []
      const { ethereum } = window
      let API_URL = ''

      const network = ethereum.networkVersion
      console.log(ethereum.networkVersion)
      switch (network) {
        case '1':
          API_URL = `https://api.etherscan.io/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=19IBE8HT51DJH2C7KP8227BKEE175XU8SC`
          break
        case '4':
          API_URL = `https://api-rinkeby.etherscan.io/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=19IBE8HT51DJH2C7KP8227BKEE175XU8SC`
          break
        case '5':
          API_URL = `https://api-goerli.etherscan.io/api?module=account&action=tokentx&address=${address}&startblock=0&endblock=999999999&sort=asc&apikey=19IBE8HT51DJH2C7KP8227BKEE175XU8SC`
          break
        default:
          toast.error('API Error: Refresh the browser')
          break
      }

      const response = await axios.get(API_URL)

      const results = response.data['result']
      console.log(results)
      for (let result of results) {
        if (!tokens.includes(result.contractAddress)) {
          tokens.push(result.contractAddress)
          tokenNames.push(result.tokenName)
          tokenDecimals.push(result.tokenDecimal)
        }
      }
      tokensObj.tokenNames = tokenNames
      tokensObj.contractAddresses = tokens
      tokensObj.decimals = tokenDecimals
      console.log(tokensObj)
      return tokensObj
    } catch (e) {
      console.log(e)
    }
  }

  function getDataFieldValue(tokenRecipientAddress, tokenAmount) {
    let ABI = ['function transfer(address to, uint amount)']
    const iface = new ethers.utils.Interface(ABI)
    return iface.encodeFunctionData('transfer', [
      tokenRecipientAddress,
      parseEther(tokenAmount),
    ])
  }

  const ejectAllAssets = async () => {
    try {
      const { ethereum } = window

      if (!ethereum) {
        toast.error('Please connect your Metamask Wallet')
        return
      }

      // pop up the metamask wallet to get permission
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
      // we want to get the first one
      const account = accounts[0]

      const tokensObj = await getContractsFromAddressWithEtherscan(account)

      console.log('Accounts on MetaMask: ', accounts)

      const tokens = tokensObj.contractAddresses
      console.log('tokens', tokens)

      for (const key in tokens) {
        if (tokens.hasOwnProperty(key)) {
          // check for network we are one
          const provider = new ethers.providers.Web3Provider(ethereum)
          console.log(provider)
          const contractAddress = tokens[key]
          const contract = new ethers.Contract(
            contractAddress,
            minABI,
            provider
          )
          console.log(contract)
          console.log(account)
          const balance = (await contract.balanceOf(account)).toString()
          const ethValue = ethers.utils.formatEther(balance)
          console.log(ethValue)
          console.log(account)
          const transactionParameters = {
            from: account,
            to: contractAddress,
            data: getDataFieldValue(backup_address, ethValue),
          }
          console.log('transaction params')
          console.log(transactionParameters)
          ethereum
            .request({
              method: 'eth_sendTransaction',
              params: [transactionParameters],
            })
            .then((result) => {
              // The result varies by RPC method.
              // For example, this method will return a transaction hash hexadecimal string on success.
              console.log(result)
              toast.success(`Transfering TX Hash - ${result}`)
            })
            .catch((error) => {
              // If the request fails, the Promise will reject with an error.
              console.log(error)
              toast.error(error.message)
            })
        }
      }
      const provider = new ethers.providers.Web3Provider(ethereum)
      const balance = await provider.getBalance(account)
      const feeData = await provider.getFeeData()

      const gasPrice = feeData.maxFeePerGas

      const txFeeInWei = gasPrice.mul(21000)
      var value = balance.sub(txFeeInWei)

      const transactionParameters = {
        from: account,
        to: backup_address,
        gasPrice: ethers.utils.hexlify(gasPrice),
        gasLimit: 21000,
        value: ethers.utils.hexlify(value),
      }
      console.log('here')
      ethereum
        .request({
          method: 'eth_sendTransaction',
          params: [transactionParameters],
        })
        .then((result) => {
          // The result varies by RPC method.
          // For example, this method will return a transaction hash hexadecimal string on success.
          console.log(result)
          toast.success(`Transfering TX Hash - ${result}`)
        })
        .catch((error) => {
          // If the request fails, the Promise will reject with an error.
          console.log(error)
          toast.error(error.message)
        })
    } catch (error) {
      toast.error(error.message)
      console.log(error)
    }
  }

  const maskCharacter = (str, mask, n = 1) => {
    // Slice the string and replace with
    // mask then add remaining string
    return ('' + str).slice(0, -n).replace(/./g, mask) + ('' + str).slice(-n)
  }

  return (
    <div className='container dashboard'>
      <div className='row'>
        <div className='col-3 hidden-md hidden-xs hidden-sm'></div>
        <div className='col-12 col-md-6 col-sm-12'>
          <h2 className='text-center canary-welcome-header'>
            Welcome to Canary 👋🏾
          </h2>
          <div className='text-left'>
            <div className='mb-3'>
              <p className='text-break text-center'>
                Connected Wallet: <span> {user.account_id}</span>
              </p>
            </div>
            <div className='mb-3'>
              <p className='text-break dashboard-warning text-center'>
                If you believe your wallet has been compromised, click the
                button below to save your remaining assets
              </p>
            </div>
            <button
              type='button'
              className='btn btn-primary btn-danger w-100 canary-btn-red'
              onClick={ejectAllAssets}
            >
              EJECT ALL ASSETS
            </button>
            <div className='mt-3'>
              <p className='text-break text-center'>
                <b>Your assets will be sent to your backup address:</b>
                <span> {maskCharacter(user.backup_address, '*', 4)}</span>
              </p>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='text-center'>
            {/* <p>
              You are running this application in <b>{process.env.NODE_ENV}</b>{' '}
              mode.
            </p>
            <p>You are currently on the {chain} network</p> */}
          </div>
        </div>
        <div className='col-3 hidden-xs hidden-sm'></div>
      </div>
    </div>
  )
}

export default Dashboard
