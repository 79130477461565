import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { reset, smsOTPTrigger, smsOTPUpdate } from '../features/auth/authSlice'
import { toast } from 'react-toastify'

function EditSMS() {
  const [smsText, setsmsText] = useState('')
  const [securityCodeText, setSecurityCodeText] = useState('')
  const [submittingSMS, setSubmittingSMS] = useState(true)
  const [submittingCode, setSubmittingCode] = useState(true)
  const [currentStep, setCurrentStep] = useState(1)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    user,
    otp_token,
    isError,
    isSuccess,
    message,
    otpSuccess,
    reauth_token,
  } = useSelector((state) => state.auth)

  useEffect(() => {
    if (!reauth_token) {
      navigate('/dashboard')
      dispatch(reset())
      toast.error('Authentication Needed')
    }
  }, [navigate, dispatch, reauth_token])

  useEffect(() => {
    if (isError) {
      toast.error('Invalid code or not a valid phone number')
      dispatch(reset())
    }

    if (isSuccess && currentStep === 1) {
      setCurrentStep(2)
      dispatch(reset())
    }

    if (otpSuccess) {
      dispatch(reset())
      navigate('/dashboard')
    }
  }, [
    currentStep,
    navigate,
    isSuccess,
    isError,
    message,
    dispatch,
    submittingSMS,
    otpSuccess,
  ])

  const handleSMSTextInput = (e) => {
    if (e.target.value === '') {
      setsmsText('')
      setSubmittingSMS(true)
    } else {
      setsmsText(e.target.value)
      setSubmittingSMS(false)
    }
  }

  const handleSecurityCodeInput = (e) => {
    if (e.target.value === '') {
      setSecurityCodeText('')
      setSubmittingCode(true)
    } else {
      setSecurityCodeText(e.target.value)
      setSubmittingCode(false)
    }
  }

  const submitPhone = async (e) => {
    e.preventDefault()
    if (smsText === '') {
      toast.error('Please Input Your Phone Number')
      return
    }

    // send the SMS
    dispatch(smsOTPTrigger({ data: smsText }))
  }

  const verifySecurityCode = async (e) => {
    e.preventDefault()
    if (securityCodeText === '') {
      toast.error('Please Input A Security Code')
      return
    }

    console.log({
      code: securityCodeText,
      otp_token: otp_token,
      reauth_token: user.reauth_token,
    })
    // dispatch API call to update
    dispatch(
      smsOTPUpdate({
        code: securityCodeText,
        otp_token: otp_token,
        reauth_token: user.reauth_token,
      })
    )
  }

  const resendCode = async () => {
    try {
      dispatch(smsOTPTrigger({ data: smsText }))
      toast.success('Please Check Your Text Again')
    } catch (error) {
      toast.error(error)
    }
  }

  return (
    <div className='container'>
      {/* render this on step 1 */}
      {currentStep === 1 && (
        <div className='row'>
          <div className='col-3 hidden-xs hidden-sm'></div>
          <div className='col-12 col-md-6 col-sm-12'>
            <h1 className='text-center canary-h1'>Update Your Phone Number</h1>
            <p className='text-left'>Where should we send alerts to?</p>
            <form onSubmit={submitPhone}>
              <div className='mb-3'>
                <label htmlFor='smsAlert' className='form-label'>
                  Phone Number
                </label>
                <input
                  onChange={handleSMSTextInput}
                  value={smsText}
                  type='text'
                  className='form-control'
                  id='smsAlert'
                  placeholder='(516) 556-1234'
                />
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  className='btn btn-primary w-100 canary-btn-blue'
                  disabled={submittingSMS ? 'disabled' : null}
                >
                  Next: Update Number
                </button>
              </div>
            </form>
          </div>
          <div className='col-3 hidden-xs hidden-sm'></div>
        </div>
      )}

      {/* render this on step 2 to verify */}
      {currentStep === 2 && (
        <div className='row'>
          <div className='col-3 hidden-xs hidden-sm'></div>
          <div className='col-12 col-md-6 col-sm-12'>
            <h3 className='text-center canary-h1'>Verify Your Phone Number</h3>
            <p className='text-left'>
              We sent a security code to your phone number, please enter it
              below
            </p>
            <form onSubmit={verifySecurityCode}>
              <div className='mb-3'>
                <input
                  onChange={handleSecurityCodeInput}
                  value={securityCodeText}
                  type='input'
                  className='form-control'
                  id='securityCode'
                  placeholder='Security Code'
                />
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  className='btn btn-primary w-100 canary-btn-blue'
                  disabled={submittingCode ? 'disabled' : null}
                >
                  Confirm Phone Number
                </button>
              </div>
              <p onClick={resendCode} className='text-end resend-code'>
                Resend Code
              </p>
            </form>
          </div>
          <div className='col-3 hidden-xs hidden-sm'></div>
        </div>
      )}
    </div>
  )
}

export default EditSMS
